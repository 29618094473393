import React from "react";
import "../App.css";

export const Privacy = () => {
  return (
    <div>
      <h1>Privacy</h1>
      <div className="text">
        
      </div>
    </div>
  );
};
