import React from 'react'
import '../App.css';

export const Home = () => {
  return (
    <div>
      <div className="text">
        
      </div>
    </div>
  )
}
